<script setup>
import Header from "./Header.vue"
import NotesComponentBox from "./NotesComponentBox.vue"
import api from "../api/api.js"
import { flashMessage } from "@/functions"
// import axios from "axios"
import ClipLoader from "vue-spinner/src/ClipLoader.vue"
import ButtonCancel from "./ButtonCancel.vue"
import ButtonSubmit from "./ButtonSubmit.vue"
import BaseInput from "./UI/BaseInput.vue"
import { readDate } from '../utils/timezone.js';
</script>

<template>
  <div class="body-container">
    <header class="">
      <!-- <Header label="Member App Notes Editor"></Header> -->
      <!-- <Header label="N01"></Header> -->
      <h2>Member App Notes Editor</h2>
      <h2>N01</h2>
    </header>
    <div v-if="isLoading">
      <clip-loader :loading="true" :color="setColor" :size="setSize"></clip-loader>
    </div>
    <div class="row" v-if="!isLoading">
      <table id="table">
        <!-- <caption>
          Calls and Authentication History
        </caption> -->
        <thead>
          <tr>
            <th>Num</th>
            <th>Name</th>
            <th>Member #</th>
            <th>Phone Number</th>
            <th>Category</th>
            <th>Notes</th>
            <th>Date Created</th>
            <th>Date Modified</th>
            <th>Interaction ID</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(note, index) in notes" :key="note.id">
            <td style="text-align: right">{{ index + 1 }}</td>
            <td style="display: none">{{ note.IndexNum }}</td>
            <td>{{ note.Name }}</td>
            <td>{{ note.MemberNumber }}</td>
            <td>{{ note.PhoneNumber }}</td>
            <td>{{ note.NoteCategory }}</td>
            <td v-html="noteFormattedText(note.NoteText)"></td>
            <td>{{ readDate(note.CreatedAt, parsedLoginUp.time_zone) }}</td>
            <td>{{ readDate(note.ModDateTime, parsedLoginUp.time_zone) }}</td>
            <td>{{ note.InteractionId }}</td>
            <td>
              <ButtonSubmit
                label="Edit"
                @click="setNoteData"
                :data-num="index + 1"
                :data-indexnum="note.IndexNum"
                :disabled="isDisableEdit" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <div class="row" v-if="!isLoading">
      <form method="POST" id="notes-form" ref="formEl" @submit.prevent="updateFraudNote">
        <div class="" style="width: 50px">
          <input
            v-model="note.IndexNum"
            type="text"
            class="form-control"
            placeholder=""
            style="width: 50px"
            disabled />
        </div>
        <div class="" style="width: 95px">
          <input
            v-model="note.Name"
            type="text"
            class="form-control"
            placeholder="Name"
            style="width: 95px" />
        </div>
        <div class="" style="width: 100px">
          <input
            v-model="note.MemberNumber"
            type="text"
            class="form-control"
            placeholder="Member #"
            style="width: 100px" />
        </div>
        <div class="" style="width: 100px">
          <input
            v-model="note.PhoneNumber"
            type="text"
            class="form-control"
            placeholder="Phone #"
            style="width: 100px" />
        </div>
        <div class="" style="width: 110px">
          <input
            v-model="note.InteractionId"
            type="text"
            class="form-control"
            placeholder="Interaction ID"
            style="width: 110px" />
        </div>
        <div>
          <select style="width: 280px" v-model="note.NoteCategory" class="note-select">
            <option value="" disabled selected></option>
            <option v-for="category in noteCategories" :key="category" :value="category">
              {{ category }}
            </option>
          </select>
        </div>
        <div class="" style="margin-right: 25px">
          <textarea
            :value="formattedNoteText"
            @input="updateFormattedNoteText"
            class="form-control"
            placeholder="Note"></textarea>
        </div>
        <div v-if="isUpdating">
          <clip-loader :loading="true" :color="setColor" :size="setSize"></clip-loader>
        </div>
        <ButtonSubmit :disabled="isDisable" v-if="!isUpdating" label="Save" />
        <ButtonCancel type="button" @click="clearNote()" :disabled="!isNoteSet" label="Cancel" />
      </form>
    </div> -->
    <!-- place the modal here -->
    <dialog :open="modalOpen">
      <div class="dialog-backdrop"></div>
      <div class="dialog-body">
        <form method="put" @submit.prevent="updateFraudNote">
          <button type="button" class="close" @click="modalOpen = false">
            <span>&times;</span>
          </button>
          <h4 class="dialog-header">Edit Note for Num {{ numID }}</h4>
          <div class="grid-container">
            <label for="">Name</label>
            <BaseInput
              v-model="note.Name"
              placeholder="Name"
              type="text"
              required
              id="email"
              style="margin-bottom: 20px" />
            <label for="">Member #</label>
            <BaseInput
              v-model="note.MemberNumber"
              placeholder="Member #"
              required
              id=""
              style="margin-bottom: 20px" />
            <label for="">Phone #</label>
            <BaseInput
              v-model="note.PhoneNumber"
              placeholder="Phone #"
              required
              id=""
              style="margin-bottom: 20px" />
            <label for="">Interaction ID</label>
            <BaseInput
              v-model="note.InteractionId"
              placeholder="Interaction ID"
              required
              id=""
              style="margin-bottom: 20px" />
            <label for="">Security Questions</label>
            <select
              class="form-control-select"
              v-model="note.NoteCategory"
              style="margin-bottom: 20px">
              <option value="" disabled selected></option>
              <option v-for="category in noteCategories" :key="category" :value="category">
                {{ category }}
              </option>
            </select>
            <label for="">Notes</label>
            <textarea
              :value="formattedNoteText"
              @input="updateFormattedNoteText"
              class="form-control-textarea"
              placeholder="Note"></textarea>
          </div>
          <br />
          <div class="button-group" style="margin-top: 25px">
            <ButtonSubmit label="Save" />
            <ButtonCancel
              @click="clearNote()"
              :disabled="!isNoteSet"
              label="Cancel"
              type="button" />
          </div>
        </form>
      </div>
    </dialog>
  </div>
</template>

<script>
export default {
  name: "NotesComponent",
  component: [Header, NotesComponentBox, ButtonSubmit, ButtonCancel],
  data: () => {
    return {
      localStorageLoginUp: localStorage.getItem("passLoginUp"),
      parsedLoginUp: "",
      setColor: "#007bff",
      setSize: "50px",
      isLoading: true,
      isUpdating: false,
      isCancelled: true,
      isNoteSet: false, // new property
      notes: [],
      isDisable: true,
      company: localStorage.getItem('companyID'),
      modalOpen: false,
      authToken: localStorage.getItem("authToken"),
      MemberNumber: localStorage.getItem("MemberNumber"),
      // MemberNumber : '5001150',
      numID: "",
      noteCategories: [],
      note: {
        MemberNumber: "",
        Name: "",
        PhoneNumber: "",
        InteractionId: "",
        NoteCategory: "",
        NoteText: "",
        ModDateTime: "",
        IndexNum: "",
      },
      isDisableEdit: false,
    }
  },
  props: {
    userData: Object,
    brandData: Object,
  },
  async mounted() {
    if(this.localStorageLoginUp){
      this.parsedLoginUp = JSON.parse(this.localStorageLoginUp);
    }
    this.setColor = this.brandData.accent_color1
    this.isLoading = true

    Promise.all([this.getFraudNotes(), this.fetchNotesCategory(), this.getIvrConfig()]);

    console.log("authTokenNOTESCREEN", this.authToken)
    console.log("MemberNumber", this.MemberNumber)
    console.log("company", this.company)
  },
  computed: {
    noteFormattedText() {
      return function (text) {
        return text.replace(/\\n/g, "<br>")
      }
    },
    formattedNoteText: {
      get() {
        return this.noteFormattedText(this.note.NoteText).replace(/<br>/g, "\n")
      },
      set(value) {
        this.updateNoteText(value)
      },
    },
  },
  methods: {
    updateFormattedNoteText(event) {
      this.note.NoteText = event.target.value.replace(/\n/g, "<br>")
    },
    updateNoteText(value) {
      this.note.NoteText = value.replace(/\n/g, "<br>")
    },
    async fetchNotesCategory() {
      this.isLoading = true
      // const headers = {
      //     Authorization: "Bearer 34eb00e1f8fc1c47b577c0b25bfaabc4",
      //     "Content-Type": "application/json",
      // }
      // try {
      //     const resNotesCategory = await axios.get(
      //         "https://genesys-api.gcm3.com/apiNODE/api_notescategory.php?action=get_notes_category_by_id&Company=1285",
      //         { headers }
      //     )
      //     if (resNotesCategory.status === 200) {
      //         this.noteCategories = resNotesCategory.data.result.map((item) => item.NoteCategory)
      //         console.log("NotesCategory by Company API result = ", resNotesCategory.data.result)
      //         console.log(this.noteCategories)
      //         this.isLoading = false
      //     }
      // } catch (error) {
      //     console.log(error)
      // }

      try {
        const { data, status } = await api.post("getNoteCategory", { company: this.company })
        if (status === 200) {
          this.noteCategories = data.map((item) => item.NoteCategory)

          this.isLoading = false
        }
      } catch (error) {
        console.log(error)
      }
    },
    setNoteData(event) {
      this.isNoteSet = true
      this.isDisable = false
      this.modalOpen = true
      console.dir(event.target.dataset.indexnum)
      const noteObj = Object.values(this.notes).find((el) => {
        return el.IndexNum == event.target.dataset.indexnum
      })


      for (const prop in this.note) {
        this.note[prop] = noteObj[prop]
      }
      this.numID = event.target.dataset.num
    },
    async updateFraudNote() {
      this.isUpdating = true
      this.note.ModDateTime = this.parsedLoginUp.time_zone
      if (!this.validation()) {
        try {
          const { status } = await api.post("updateFraudNote", this.note)

          if (status === 200) {
            console.log("updated", this.notes)
            flashMessage(
              this.$flashMessage,
              this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
              this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
              "Notes updated successfully."
            )

            this.getFraudNotes()
            this.isCancelled = false
            this.clearNote()
          }
        } catch (error) {
          console.log(error)
        }
      } else {
        flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
          this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
          "Input fields must be filled out."
        )
      }
      this.isUpdating = false
    },
    async getFraudNotes() {
      try {
        const { data } = await api.get(`getFraudNotes?company=${this.company}&memberNumber=${this.MemberNumber}`)
        console.log("from this.notes",data)
        this.notes = data
        this.isLoading = false
      } catch (error) {
        console.log(error)
      }
    },
    clearNote() {
      this.isDisable = true
      this.isNoteSet = false
      this.modalOpen = false
      for (const prop in this.note) {
        this.note[prop] = ""
      }
      if (this.isCancelled === true) {
        flashMessage(
          this.$flashMessage,
          this.brandData.accent_color1 ? this.brandData.accent_color1 : "#FF7F50",
          this.brandData.flash_text_color ? this.brandData.flash_text_color : "#ffffff",
          "Notes cancelled successfully."
        )
      }
      this.isCancelled = true
    },
    validation() {
      return Object.values(this.note).some((value) => value == "")
    },
    async getIvrConfig() {
      try {
        const { data } = await api.post("getIVRConfigOptions", { Company: this.company });
        this.isDisableEdit = data.DisableEditN01;
  
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
}
</script>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
}

caption {
  text-align: left;
  margin: 1rem 0;
}

input,
textarea {
  padding: 4px 8px;
  /* font-size: 14px; */
}

textarea {
  height: 100px;
  /* width: 300px; */
  vertical-align: text-top;
}

tr td:nth-child(1) {
  text-align: start;
  display: table-cell;
}

dialog {
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  /* border-radius: 0.4rem; */
  padding: 0;
  width: 551px;
  /* width: 95%; */
  position: fixed;
  left: 0;
  top: 50%;
  margin-top: -309px;
  /* margin-top: -104px; */
  background-color: #fff;
  z-index: 100;
}

.dialog-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.2); */
  z-index: -1001;
}

.dialog-body {
  z-index: 10;
  background-color: #fff;
  padding: 2rem;
  /* border-radius: 0.4rem; */
  padding-top: 40px;
}
.dialog-header {
  margin: 0;
  margin-bottom: 16px;
  font-size: 24px;
}
.grid-container {
  display: inline-grid;
  align-items: baseline;
  column-gap: 10px;
  grid-template-columns: 144px 329px;
  grid-template-rows: 60px;
}

.grid-container > label {
  display: inline-block;
  vertical-align: middle;
  text-align: right;
}

.dialog-form > .form-group {
  display: inline-block;
  margin: 0 10px;
  vertical-align: top;
}
.body-container {
  align-self: unset;
  min-width: 1200px;
}

.row {
  margin-bottom: 4rem;
}

#notes-form > div {
  display: inline-block;
  margin: 0 10px;
  vertical-align: top;
}

.input-indexnum {
  width: 50px;
}

.btn {
  margin: 0 5px;
  padding: 7px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  border: 0;
  width: 70px;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

button:disabled {
  pointer-events: none;
  opacity: 0.65;
}

.note-select {
  min-width: 280px;
  /* Adjust the width as per your preference */
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
</style>
